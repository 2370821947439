/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.1/dist/jquery.min.js
 * - /npm/skrollr@0.6.26/dist/skrollr.min.js
 * - /npm/skrollr-menu@1.0.3/dist/skrollr.menu.min.js
 * - /npm/skrollr-stylesheets@1.0.0/dist/skrollr.stylesheets.min.js
 * - /npm/in-view@0.6.1/dist/in-view.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
